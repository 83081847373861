import {
  greenTheme,
  grey,
  lighBlacktFont,
  lightGrey,
  redish,
  whiteColor,
} from "../../styles";
import styled from "styled-components";

export const Main = styled.div`
  overflow: auto;
  max-height: calc(100dvh - 0px);
  transition: all 0.3s ease;
  // @media only screen and (max-width: 457px) {
  //   max-height: calc(100vh - 100px);
  // }
`;

export const BannerDiv = styled.div`
  margin: 0 25px;
`;

export const StickyDivContainer = styled.div`
  position: sticky;
  top: -1px;
  background-color: #f0f0f0;
  z-index: 51;
  padding: 0px 25px 0px 25px;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  // align-items: center;
  justify-content: space-between;

  @media only screen and (max-width: 580px) {
    flex-direction: column;
    // padding: 0px 10px;
  }

  @media only screen and (max-width: 990px) {
    display: grid;
    grid-template-columns: 2fr 1fr;
    width: 100%;
  }

  @media only screen and (max-width: 767px) {
    grid-template-columns: auto;
  }

  @media only screen and (max-width: 760px) {
    &.sticky-head {
      display: flex;
      flex-direction: row;
    }
  }

  @media only screen and (max-width: 575px) {
    display: grid;
    grid-template-columns: 1fr;
    &.sticky-head {
      display: flex;
      flex-direction: column;
    }
  }
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  &.flex-1 {
    flex: 1;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.gap20 {
    gap: 20px;
  }

  &.j-fs {
    justify-content: flex-start;
  }

  &.a-fs {
    align-items: flex-start;
  }

  @media only screen and (max-width: 767px) {
    &.sticky-head {
      flex-direction: column;
      align-items: flex-start;
      input {
        width: 100%;
      }
    }
  }
`;

export const InputDiv = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  @media only screen and (max-width: 767px) {
    width: 100%;
  }
`;

export const BottomC0ntainer = styled.div`
  padding: 10px 25px;
`;

export const Text = styled.div`
  font-family: "NunitoSans600";
  font-size: 30px;
  font-weight: 600;
`;

export const Category = styled.div`
  font-family: "NunitoSans600";
  font-size: 20px;
  font-weight: 600;
  padding: 10px 0px;
  color: ${redish};
`;

export const Input = styled.input`
  background: ${whiteColor} !important;
  outline-width: thin;
  // border: transparent;
  border: 1px solid ${grey};
  padding: 7px 8px;
  border-radius: 4px 0 0px 4px;
  color: rgb(102 92 92);
  font-size: 16px;

  &:active,
  &:hover {
    border: 1px solid ${lightGrey};
    // outline: none;
  }
  &:focus {
    outline: none;
  }
`;

export const CardContainer = styled.div`
  // padding: 10px 15px 10px 0px;
  // overflow: auto;
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-column-gap: 20px;
  grid-row-gap: 20px;
  flex: 80%;
  width: 100%;

  @media only screen and (max-width: 991px) {
    grid-template-columns: repeat(2, 1fr);
    width: 100%;
  }

  @media only screen and (max-width: 580px) {
    display: flex;
    flex-direction: column;
    flex: 1;
  }
`;

export const Image = styled.img`
  width: 100%;
  height: 100%;
  aspect-ratio: 4/3;
  // flex: 1;
  overflow: hidden;
  //   flex: 45%;
  // max-height: 200px;
  border-radius: 5px;
  overflow-clip-margin: content-box;
  overflow: clip;
  vertical-align: middle;
`;

export const Conteiner = styled.div`
  position: relative;
  display: flex;
  // padding: 0px 10px;
  flex-direction: column;
  // gap: 15px;
  //   border: 1px solid red;
  border-radius: 3px;
  // overflow: hidden;
  width: 100%;
  height: 325px;
  // flex: 1;
`;

//left-side
export const LeftContainer = styled.div`
  display: flex;
  flex-direction: column;
  flex: 20%;
  // width: 100%;
  gap: 10px;
  position: sticky;
  top: 70px;
  padding-left: 20px;
  @media only screen and (max-width: 767px) {
    display: none;
    visibility: hidden;
  }
`;

export const ImageDiv = styled.div`
  border: 1px solid ${grey};
  border-radius: 8px;
  overflow: hidden;
  &.img-height {
    height: 300px;
    flex: 1;
  }

  &.cart {
    padding: 10px;
    background-color: ${whiteColor};
    border-radius: 4px;
    color: black;
  }
`;

export const EmptyDivText = styled.div`
  font-size: 1.25rem;
  font-weight: 300;
  // color: #000;
`;

//cart

export const Proceed = styled.div`
  padding: 0.375rem 1.125rem;
  margin: 10px;
  border: 0.0625rem solid #ff0000;
  display: inline-block;
  background-color: #ff0000;
  border-radius: 0.3125rem;
  text-transform: uppercase;
  font-size: 0.875rem;
  font-weight: 500;
  cursor: pointer;
  line-height: inherit;
  -webkit-box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
  box-shadow: 0 4px 8px 0 rgb(0 0 0 / 12%);
  color: ${whiteColor};
`;

export const Payment = styled.div`
  display: flex;
  flex-direction: revert;
  justify-content: space-between;
  margin: 5px 1px 10px 20px;
`;

export const ItemConteiner = styled.div`
  display: flex;
  flex-direction: column;
  gap: 30px;
`;

export const ViewCartButton = styled.div`
  position: fixed;
  z-index: 99999;
  width: 100%;
  background-color: #ff0000;
  bottom: 0;
  left: 0;
  padding: 10px;
  float: left;
  font-size: 20px;
  color: #fff;
  display: flex;
  flex-direction: revert;
  // align-items: center;
  justify-content: space-between;
  color: white;

  svg {
    transform: rotate(180deg);
    stroke: ${whiteColor};
    path {
      fill: ${whiteColor};
    }
  }

  @media only screen and (min-width: 759px) {
    display: none;
  }
`;

export const IconDiv = styled.div`
  background-color: ${lighBlacktFont};
  padding: 8.5px 9px;
  display: flex;
  align-items: center;
  flex: 1;
  border-radius: 0 4px 4px 0;
  cursor: pointer;
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;

  &.flex-1 {
    flex: 1;
  }
  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.a-fs {
    align-items: flex-start;
  }

  &.n0Gap {
    gap: unset;
  }
`;

export const OutOfBox = styled.div`
  font-size: 10px;
  color: red;
`;

export const ProductName = styled.div`
  font-size: 15px;
  // max-width: 70%;
  // width: 100%;
  // white-space: nowrap;
  // overflow: hidden;
  // text-overflow: ellipsis;

  s {
    color: #9c9a9a;
  }

  &.price {
    color: #5b5b5b !important;
  }
`;

export const BestSeller = styled.div`
  color: #00ad00;
  font-size: 9px;
  font-weight: 600;
  font-style: italic;
  text-decoration: underline;
  min-width: 100px;

  svg {
    height: 10px;
    width: 10px;
    path {
      fill: #00ad00;
    }
  }

  &.simple {
    font-size: 10px;
    // font-weight: unset;
    font-style: normal;
    text-decoration: none;
    display: block;
    text-align: end;
    padding-right: 20px;

  }
`;
