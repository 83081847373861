import { Hspace, Hline, Vline } from "../../styles"
import Icon from "../icon"
import { AddressText, BottomTitle, Column, Description, ExpenseSplit, Help, IconDiv, OrderDiv, OrdersContainer, OrderTopText, Popup, Price, ProdList, ProggressbarContainer, ProgressbarItem, Row } from "./order-item-components"
import ReactStars from "react-rating-stars-component";
import './progressbar.css'
import { orderTimeAndDate } from "../../utils";
import { useCartContext } from "../../context";
import { useCallback, useEffect, useMemo, useState } from "react";
import { data } from "jquery";
import Overlay from "../overlay";
import ReviewProd from "../review-prod";
import ReturnForm from "../return-form";
import moment from "moment";
import { capitalize } from "../../utils";



type Props = {
    exapnded?: boolean
    setExpand?: any;
    foodReview?: any;
    deliveryReview?: any;
    arrowClick?: () => void;
    data?: any;
}

const OrderItem = ({ exapnded, setExpand, foodReview, deliveryReview, arrowClick, data }: Props) => {
    const { totalAmount } = useCartContext()
    const JSONParse = (string: any) => JSON.parse(string);
    const [isOpenOverlay, setIsOpenOverlay] = useState<any>({ show: false, component: null, loading: false, items: null })
    const [reviewProds, setReviewProds] = useState<any>(JSONParse(data?.product_details))
    const [order, setOrder] = useState<string>("");
    const [confirm, setConfirm] = useState<string>("");
    const [shipped, setShipped] = useState<string>("");
    const [transit, setTransit] = useState<string>("");
    const [deliver, setDeliver] = useState<string>("");
    const [packed, setPacked] = useState<string>("");
    const [showForm, setShowForm] = useState<boolean>(false);
    const [orderNo, setOrderNo] = useState<string>('')


    function orderStatus(status: any) {
        switch (status) {
            case 'Order':
                setOrder(`completed active`)
                setDeliver("")
                setConfirm("")
                setTransit("")
                setPacked("")
                setShipped("")
                break;
            case 'Confirmed':
                setOrder(`completed`)
                setConfirm(`completed active`)
                setDeliver("")
                setTransit("")
                setPacked("")
                setShipped("")
                break;

            case 'Packed':
                setOrder(`completed`)
                setConfirm(`completed`)
                setPacked(`completed active`)
                setDeliver("")
                setTransit("")
                setShipped("")
                break;
            case 'Shipped':
                setOrder(`completed`)
                setConfirm(`completed`)
                setPacked(`completed`)
                setShipped(`completed active`)
                setDeliver("")
                setTransit("")
                break;
            case 'Intransit':
                setOrder(`completed`)
                setConfirm(`completed`)
                setPacked(`completed`)
                setShipped(`completed`)
                setTransit(`completed active`)
                setDeliver("")
                break;
            case 'Delivered':
                setOrder(`completed`)
                setConfirm(`completed`)
                setPacked(`completed`)
                setShipped(`completed`)
                setTransit(`completed`)
                setDeliver(`completed active`)
                break;
        }
    }

    const handleHelp = (orderNo: any) => {
        console.log("🚀 ~ file: order-item.tsx:95 ~ handleHelp ~ orderNo:", orderNo)
        setShowForm(!showForm);
        setOrderNo(orderNo)
    }

    useEffect(() => {
        orderStatus(data?.track?.track_status)
    }, [data])



    return (
        <OrdersContainer className={exapnded ? "expand" : ""}>
            <OrderDiv onClick={arrowClick}>
                <Row className="b-bottom start">
                    <OrderTopText>
                        <div>Order: {data?.order_no}</div>
                        <div>{moment(data?.created_at, 'DD-MMM-YYYY hh:mm:ss A').format('DD-MMM-YYYY hh:mm A')}</div>
                    </OrderTopText>
                    {data?.track?.track_status == "Delivered" &&
                        <Help onClick={() => handleHelp(data?.order_no)}>Help</Help>}
                </Row>
                <Row className="start">
                    <OrderTopText>
                        {data?.track?.track_status == "Delivered" ?
                            <>
                                <div>Delivered: {JSONParse(data?.product_details)?.length} item(s)</div>
                                <div>{moment(data?.track?.deliver_at, 'DD-MMM-YYYY hh:mm:ss A').format('DD-MMM-YYYY hh:mm A')}</div>
                            </> : <>
                                <div>Order Status</div>
                                <OrderTopText className="bold">{data?.track?.track_status}</OrderTopText>
                            </>}
                    </OrderTopText>
                    <Price><span>₹ {data?.total_amount}</span>
                        <IconDiv toggle={exapnded} onClick={arrowClick}><Icon name="fillArrow" /></IconDiv>
                    </Price>
                </Row>
                <Hspace ht={10} />
                <ProdList>
                    {JSONParse(data?.product_details)?.map((item: any, index) => (
                        <Description key={index.toString()}>
                            <div>{item?.name}</div>
                            <Price>₹ {item?.amount}</Price>
                        </Description>
                    ))}
                </ProdList>
                {/* expense */}
                <Hspace ht={10} />
                <ExpenseSplit>
                    <div>Cart Amount</div><div>₹ {data?.sub_total}</div>
                </ExpenseSplit>
                <ExpenseSplit>
                    <div>Discount</div><div>₹ {data?.discounted_amount}</div>
                </ExpenseSplit>
                <ExpenseSplit>
                    <div>Tax</div><div>₹ {data?.tax}</div>
                </ExpenseSplit>
                <ExpenseSplit>
                    <div>Delivery Fee</div><div>₹ {data?.delivery_charge}</div>
                </ExpenseSplit>
                {data?.annas_amount != null &&
                    <ExpenseSplit>
                        <div>Paid by Annas</div><div>₹ {data?.annas_amount}</div>
                    </ExpenseSplit>}
                <ExpenseSplit>
                    <div>Amount Paid</div><div>₹ {data?.paid_amount}</div>
                </ExpenseSplit>

                <ProggressbarContainer>
                    <ProgressbarItem className={`stepper-item ${order}`}>
                        <div className="step-counter">1</div>
                        <div className="step-name">Order</div>
                    </ProgressbarItem>
                    <ProgressbarItem className={`stepper-item ${confirm}`}>
                        <div className="step-counter">2</div>
                        <div className="step-name">Confirm</div>
                    </ProgressbarItem>
                    <ProgressbarItem className={`stepper-item ${packed}`}>
                        <div className="step-counter">3</div>
                        <div className="step-name">Packed</div>
                    </ProgressbarItem>
                    <ProgressbarItem className={`stepper-item ${shipped}`}>
                        <div className="step-counter">4</div>
                        <div className="step-name">Shipped</div>
                    </ProgressbarItem>
                    <ProgressbarItem className={`stepper-item ${transit}`}>
                        <div className="step-counter">5</div>
                        <div className="step-name">Intransit</div>
                    </ProgressbarItem>
                    <ProgressbarItem className={`stepper-item ${deliver}`}>
                        <div className="step-counter">6</div>
                        <div className="step-name">Delivered</div>
                    </ProgressbarItem>
                </ProggressbarContainer>

                <Hline />

                <Row className="noJustify">
                    <Column className="flex-1">
                        <BottomTitle>Delivered at: {capitalize(data?.address?.location)}</BottomTitle>
                        <div>
                            <AddressText>HOUSE NO : - {data?.address?.flat_house_no}, {data?.address?.address1}, {data?.address?.address2}</AddressText>
                            <AddressText>{data?.address?.street}, {data?.address?.city},{data?.address?.country} {data?.address?.zip}, </AddressText>
                            <AddressText>Landmark: {data?.address?.landmark}</AddressText>
                        </div>
                    </Column>
                    <Vline ht="100%" />
                    <Column className="flex-1">
                        {/* <BottomTitle>Delivered at: {capitalize(data?.address?.location)}</BottomTitle> */}
                        <AddressText>Coupon used: {data?.coupon ? data?.coupon?.coupon_code : "NA"}</AddressText>
                    </Column>
                    <Vline ht="100%" />
                    <Column className="flex-1">
                        <BottomTitle>Review Product</BottomTitle>
                        {/* <Hspace /> */}
                        <Row>
                            <BottomTitle>Product(s)</BottomTitle>
                            <Help className="feedback" onClick={() => setIsOpenOverlay({ show: true, component: 'prod-review', loading: false })}>Reviews</Help>
                        </Row>
                        {/* <Hline />
                        <Row>
                            <BottomTitle>Delivery</BottomTitle>
                            <Help className="feedback">Feedback</Help>
                        </Row> */}
                    </Column>
                </Row>
            </OrderDiv>
            {
                isOpenOverlay.show &&
                <Overlay>
                    {isOpenOverlay.component == 'prod-review' &&
                        <ReviewProd
                            onClose={() => setIsOpenOverlay({ show: false, component: 'prod-review', loading: false })}
                            orderId={data?.order_no}
                        />}
                </Overlay>
            }
            {showForm &&
                <Overlay>
                    <Popup>
                        <ReturnForm
                            orderNo={orderNo}
                            setOrderNo={setOrderNo}
                            onSubmit={() => { }}
                            onClose={() => setShowForm(false)}
                            onDelete={() => { }} />̦̦̦̦
                    </Popup>
                </Overlay>
            }
        </OrdersContainer>
    )
}

export default OrderItem