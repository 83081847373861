import React, { useEffect, useState } from 'react'
import { getProductRecoms } from '../../@services'
// import { Card, CardHolder, Comment, Container, Heading, PaginationDiv, Row, Text } from './prod-recom-components'
import { Container, Heading, } from './prod-recom-components'
// import ReactStars from "react-rating-stars-component";
import { Icon, RecomSlick } from '../../components';
import { BannerDiv } from '../homescreen/homescreen-components';

const foodStar = {
    size: 20,
    count: 5,
    color: "black",
    activeColor: "red",
    a11y: true,
    isHalf: true,
    emptyIcon: <Icon name="emptyStar" />,
    halfIcon: <Icon name="halfStar" />,
    filledIcon: <Icon name="filledStar" />,
    edit: false,
    onChange: (newValue) => {
        console.log(`Example 2: new value is ${newValue}`);
    }
};
const ProductReviews = ({ productId }: any) => {
    const [loading, setLoading] = useState<boolean>(false);
    const [recoms, setRecoms] = useState<any>([]);
    const [page, setPage] = useState<number>(1);

    const getRecoms = async () => {
        setLoading(true)
        let param: any = {
            "product_id": productId,
            // page: page
        }
        await getProductRecoms(param).then((res: any) => {
            if (res && res?.status) {
                console.log(res?.data?.data)
                setRecoms(res?.data?.data);
                // handleRating(res?.data?.data)
                setLoading(false)
            } else {
                console.log(res?.response?.data)
                setLoading(false)
            }
        })
    }

    useEffect(() => {
        if (productId) {
            getRecoms();
        }
    }, [])
    // console.log(reviews)

    if (loading) {
        return <div></div>
    } else {
        return (
            <Container>
                <Heading>Product Recommendations</Heading>
                <BannerDiv>
                    <RecomSlick data={recoms} />
                </BannerDiv>
            </Container>
        )
    }
}

export default ProductReviews