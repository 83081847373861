import { whiteColor, grey, greyBackground, darkFont, lighBlacktFont } from "../../styles";
import styled from "styled-components";

export const OrdersContainer = styled.div`
display: flex;
flex-direction: column
padding: 20px 25px;
gap: 20px;
max-height: 150px;
height: 100%;
overflow: hidden;
transition: all 0.3s ease;
margin-bottom: 20px;
letter-spacing: 0.4px;
line-height: 20px;

&.expand {
    max-height: min-content;
    height: 100%;
    transition: all 0.5s ease;
    overflow: auto;

}

@media screen and (max-width: 677px) {
    max-height: 150px;
}

@media screen and (max-width: 458px) {
  max-height: 150px;
}

`;

export const OrderDiv = styled.div`
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex: 1;
  gap: 20px;
  background-color: ${whiteColor};
`;

export const Row = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  //   margin: 10px 20px 0px 20px;
  font-size: 0.9em;
  color: ${lighBlacktFont};

  &.start {
    align-items: flex-start;
  }

  &.end {
    align-items: flex-end;
  }

  &.noJustify {
    justify-content: unset;
    gap: 20px;
    align-items: flex-start;
    @media only screen and (max-width: 767px) {
      flex-direction: column;
    }
  }

  &.b-bottom {
    border-bottom: 1px solid ${grey};
    padding-bottom: 7px;
  }
`;

export const OrderTopText = styled.div`
  max-width: 70%;
  font-size: 14px;

  &.bold {
    font-weight: 600;
  }
`;

export const Column = styled.div`
  display: flex;
  flex-direction: column;
  gap: 10px;
      width: 100%;


  &.flex-1 {
    flex: 1;
  }

  &.flex-2 {
    flex: 2;
  }

  &.gap10 {
    gap: 10px;
  }

  &.gap30 {
    gap: 30px;
  }

  &.noGap {
    gap: 0px;
    width: 100%;
  }
`;

export const Help = styled.div`
  background-color: #ff0000;
  color: #fff;
  padding: 6px 20px 10px;
  border-radius: 5px;
  box-shadow: 2px 3px 4px -2px #000;
  font-weight: 600;

  &:hover {
    cursor: pointer;
    box-shadow: unset;
  }

  &.feedback {
    padding: 4px;
    min-width: 80px;
    text-align: center;
    background-color: #b5ff009c;
    color: black;
  }
`;

export const Price = styled.div`
  color: #9c9a9a;
  display: flex;
  align-items: flex-start;
  gap: 5px;

  min-width: 75px;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  span {
    font-size: 17px;
  }
`;

export const IconDiv = styled.span`
  transition: all 0.3s ease;
  cursor:pointer;
  svg {
    height: 18px;
    width: 18px
    transition: all 0.3s ease;
    // transform: rotate(180deg);
  }

  ${({ toggle }: { toggle?: boolean }) => {
    if (toggle) {
      return `
       svg {
        transition: all 0.3s ease 0s;
        transform: rotate(180deg);
        }
       `;
    }
  }}
`;

export const ProdList = styled.div`
background-color: ${greyBackground};
border-radius: 8px;
padding: 5px 0px;
`
export const Description = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  padding: 10px 20px;
  letter-spacing: 0.4px;
// line-height: 20px;
font-size: 13px;́
`;

export const ExpenseSplit = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-radius: 8px;
  margin: 3px 20px;
  color: ${darkFont};
`;

export const BottomTitle = styled.div`
  font-size: 15px;
  color: ${darkFont};
`;

export const AddressText = styled.div`
  font-size: 15px;
`;

//progressbar

export const ProggressbarContainer = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
`;

export const ProgressbarItem = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  font-size: 14px;
  transition: all 1s ease;


  &::before {
    position: absolute;
    content: "";
    border-bottom: 2px solid #ccc;
    width: 100%;
    top: 15px;
    left: -50%;
    z-index: 2;
    transition: all 1s ease;
  }

  @media (max-width: 768px) {
    font-size: 10px;
  }
`;

export const Popup = styled.div`
  display: flex;
  flex-direction: Column;
  align-items: center;
  //   justify-content: space-between;
  overflow: auto;
  //   background-color: #f9f9ff;
  max-height: 95vh;
  padding: 10px;
  max-width: 800px;
  width: 100%;
`;
